import ReactGA from 'react-ga4';

export const TriggerGAEvent = (
  category: string,
  action: string,
  label: string,
) => {
  ReactGA.event({
    category,
    action,
    nonInteraction: true,
    label,
  });
};
